import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useForm, FormProvider } from 'react-hook-form';
import { RichTextBlock } from 'prismic-reactjs';
import qs from 'querystring';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';
import { Spinner } from '@chakra-ui/react';

import { Button } from '@/components';
import { InputsContainer } from '@/components/Contact';
import PrismicComponent from '@/prismic/prismicComponent';
import mq from '@/styles/mq';

import theme from '@/styles/theme';

interface ContactFormProps extends React.FormHTMLAttributes<HTMLFormElement> {
    contactTitle: {
        raw: RichTextBlock[];
    };
    contactInfos: {
        raw: RichTextBlock[];
    };
    lang: string;
}

const FormSection = styled.section`
    display: flex;
    flex-direction: row-reverse;
    box-sizing: border-box;
    ${mq.mqMax('lg')}{
        flex-direction: column-reverse;
    }
`;

const Form = styled(motion.form)`
    padding: 4rem 2rem;
    width: 60%;
    box-sizing: border-box;
    ${mq.mqMax('lg')}{
        width: 100%;
        padding: 2.5rem;
    }
    ${mq.mqMax('sm')}{
        padding: 2rem;
    }
`;

const FormContainer = styled(motion.section)`
    padding: 2rem 0;
    box-sizing: border-box !important;
    > div{
        box-sizing: border-box;
        width: 100%;

    }
    > div:nth-of-type(1){
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        > section{
            width: 40%;
        }
        
    }
    > div:nth-of-type(2){
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        > section{
            width: 40%;
        }
    }
    > div:nth-of-type(3){
        box-sizing: border-box;
        > div{
            width: 100%;
            box-sizing: border-box;
        }
    }
    ${mq.mqMax('md')}{
        > div{
        margin: 1.5rem 0;

        }
        > div:nth-of-type(1){
            > div{
                width: 100%;
            }
        }
        > div:nth-of-type(2){
            > div{
                width: 100%;
            }
        }
        > div:nth-of-type(3){
            > div{
                width: 100%;
            }
        }
    }
    ${mq.mqMax('sm')}{
        > div:nth-of-type(1){
            flex-direction: column;
            > section{
                width: 100%;
            }
        }
        > div:nth-of-type(2){
            flex-direction: column;
            > section{
                width: 100%;
            }
        }
        > div:nth-of-type(3){
            > section{
                width: 100%;
            }
        }
    }
`;

const InfosContainer = styled(motion.div)`
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    background-color: ${theme.colors.colorPrimary};
    a{
        text-decoration: underline;
        transition: .3s;
        &:hover{
            color: ${theme.colors.colorGreen};
        }
    }
    h1, h3, p{
        color: ${theme.colors.white};
    }
    h2{
        color: ${theme.colors.colorGreen};
    }
    ${mq.mqMax('lg')}{
        width: 100%;
    }
`;


const InputsDatas = {
    'fr': {
        nom: {
            title: 'Entrez votre nom',
            label: 'Nom',
            name: 'Nom',
            type: `input`,
        },
        prenom: {
            title: 'Entrez votre prénom',
            label: 'Prénom',
            name: 'Prenom',
            type: `input`,
        },
        telephone: {
            title: 'Entrez votre numéro de téléphone',
            label: 'Téléphone',
            name: 'Telephone',
            type: `input`,
        },
        email: {
            title: 'Entrez votre adresse e-mail',
            label: 'E-mail',
            name: 'Email',
            type: `input`,
        },
        titre: {
            title: 'Entrez le titre de votre message',
            label: 'Titre',
            name: 'Titre',
            type: `input`,
        },
        message: {
            title: 'Entrez votre message',
            label: 'Message',
            name: 'Message',
            type: `textarea`,
        },
        button: 'ENVOYER'
    },
    'en': {
        nom: {
            title: 'Enter your lastname',
            label: 'Lastname',
            name: 'Nom',
            type: `input`,
        },
        prenom: {
            title: 'Enter your firstname',
            label: 'Firstname',
            name: 'Prenom',
            type: `input`,
        },
        telephone: {
            title: 'Enter your phone number',
            label: 'Phone',
            name: 'Telephone',
            type: `input`,
        },
        email: {
            title: 'Enter your e-mail adress',
            label: 'E-mail',
            name: 'Email',
            type: `input`,
        },
        titre: {
            title: 'Enter the title of your message',
            label: 'Title',
            name: 'Titre',
            type: `input`,
        },
        message: {
            title: 'Enter your message',
            label: 'Message',
            name: 'Message',
            type: `textarea`,
        },
        button: 'SEND'
    },
    'nl': {
        nom: {
            title: 'Vul uw naam in',
            label: 'Naam',
            name: 'Nom',
            type: `input`,
        },
        prenom: {
            title: 'Vul uw voornaam in',
            label: 'Voornaam',
            name: 'Prenom',
            type: `input`,
        },
        telephone: {
            title: 'Vul je telefoonnummer in',
            label: 'Telefoonnummer',
            name: 'Telephone',
            type: `input`,
        },
        email: {
            title: 'Voer uw e-mailadres in',
            label: 'E-mail',
            name: 'Email',
            type: `input`,
        },
        titre: {
            title: 'Voer de titel van uw bericht in',
            label: 'Titel',
            name: 'Titre',
            type: `input`,
        },
        message: {
            title: 'Voer uw bericht in',
            label: 'Bericht',
            name: 'Message',
            type: `textarea`,
        },
        button: 'STUREN'
    }
};

interface FormDatas {
    prenom: string;
    nom: string;
    telephone: number;
    email: string;
    titre: string;
    message: string;
    globalelectricscontact: string;
}

const encode = (data: any) => {
    return Object.keys(data)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
        .join('&');
};

export const ContactForm = ({ lang, contactTitle, contactInfos }: ContactFormProps): JSX.Element => {
    const currentLang = lang.split('-')[0];
    //@ts-ignore
    const { nom, prenom, telephone, email, titre, message, button } = InputsDatas[currentLang];
    const [success, setSuccess] = useState<string>();
    const [error, setError] = useState<string>();
    const methods = useForm<FormDatas>();
    const controls = useAnimation();

    const { ref, inView } = useInView({
        threshold: 0,
        triggerOnce: true,
    });

    const list = {
        visible: {
            opacity: 1,
            transition: {
                when: "beforeChildren",
                staggerChildren: 0.3,
            },
        },
        hidden: {
            opacity: 1,
            transition: {
                when: "afterChildren",
            },
        },
    }

    const item = {
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
    }

    useEffect(() => {
        if (inView === true) {
            controls.start('visible')
        }
    }, [inView])

    const {
        handleSubmit,
        formState: { isSubmitting },
        register,
        reset,
    } = methods;


    const onSubmit = handleSubmit(async (data) => {
        try {
            await fetch('/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: encode({
                    ...data,
                    'form-name': `globalelectrics-contact`,
                }),
            });
            setSuccess('Votre formulaire a bien été envoyé');
            reset();
            setTimeout(() => {
                setSuccess('');
            }, 4000);
        } catch (e) {
            setError('Désolé une erreur est survenue, veuillez réessayer');
            reset();
            setTimeout(() => {
                setError('');
            }, 4000);
        }
    });



    return (
        <FormProvider {...methods}>
            <FormSection ref={ref}>
                <Form
                    name="globalelectrics-contact"
                    onSubmit={onSubmit}
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    initial="hidden"
                    animate={controls}
                    variants={list}
                >
                    <input type="hidden" name="bot-field" />
                    <input
                        {...register('globalelectricscontact', { required: true })}
                        type="hidden"
                        name="form-name"
                        value="globalelectrics-contact"
                        
                    />
                    <motion.div
                        variants={item}
                        transition={{ duration: 0.3, times: [0, 0.2, 1] }}
                    >
                        <PrismicComponent content={contactTitle.raw} />
                    </motion.div>
                    <FormContainer
                        initial="hidden"
                        animate={controls}
                        variants={list}
                    >
                        <motion.div 
                            variants={item}
                            transition={{ duration: 0.3, times: [0, 0.2, 1] }}
                        >
                            <InputsContainer
                                type={nom.type}
                                title={nom.title}
                                label={nom.label}
                                name={nom.name}
                            />
                            <InputsContainer
                                type={prenom.type}
                                title={prenom.title}
                                label={prenom.label}
                                name={prenom.name}
                            />
                        </motion.div>
                        <motion.div
                            variants={item}
                            transition={{ duration: 0.3, times: [0, 0.2, 1] }}
                        >
                            <InputsContainer
                                type={email.type}
                                title={email.title}
                                label={email.label}
                                name={email.name}
                            />
                            <InputsContainer
                                type={telephone.type}
                                title={telephone.title}
                                label={telephone.label}
                                name={telephone.name}
                            />
                        </motion.div>
                        <motion.div
                            variants={item}
                            transition={{ duration: 0.3, times: [0, 0.2, 1] }}
                        >
                            <InputsContainer
                                type={titre.type}
                                title={titre.title}
                                label={titre.label}
                                name={titre.name}
                            />
                            <InputsContainer
                                type={message.type}
                                title={message.title}
                                label={message.label}
                                name={message.name}
                            />
                        </motion.div>
                    </FormContainer>
                    <div css={css`text-align: center;`}>
                        {isSubmitting ? (
                            <Spinner size="lg" color={theme.colors.colorPrimary} />
                        ) : (
                            <Button type="submit" variant="primaryreverse" css={css`padding: 10px 20px;`}>
                                {button}
                            </Button>
                        )}
                    </div>
                    {success && (
                        <div
                            css={css`
                                    font-size: 1.2rem;
                                    text-align: center;
                                    padding: 1.5rem;
                                    color: ${theme.colors.colorSuccess};
                                `}
                        >
                                {success}
                            </div>
                        )}
                    {error && (
                            <div
                                css={css`
                                    font-size: 1.2rem;
                                    text-align: center;
                                    padding: 1.5rem;
                                    color: ${theme.colors.colorError};
                                `}
                            >
                                {error}
                        </div>
                    )}
                </Form>
            <InfosContainer
                    initial="hidden"
                    animate={controls}
                    variants={list}
            >
                <motion.div
                    variants={item}
                    transition={{ duration: 0.3, times: [0, 0.2, 1] }}
                >
                    <PrismicComponent content={contactInfos.raw} />
                </motion.div>
            </InfosContainer>
            </FormSection>
        </FormProvider>
    );
};

